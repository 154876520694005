import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Web developer based on the Hibiscus Coast, Auckland, NZ.',
            },
            {
              name: 'keywords',
              content:
                'Frontend developer, Front end developer, Web developer, Website, Hibiscus Coast, Rodney, Auckland, JavaScript, React, JamStack, Gatsby',
            },
          ]}
        >
          <html lang="en" />
          <link
            href="https://fonts.googleapis.com/css?family=Architects+Daughter&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <main>{children}</main>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
